'use client';

import { debounce } from 'lodash';
import { usePathname } from 'next/navigation';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useGetMyProfile } from '@/features/shared/profile/hooks';

import { useDeviceInfo } from '@/shared/hooks/use-device-info';
import { checkUserLoggedIn } from '@/shared/utils';

import {
  VerificationDocType,
  verificationService,
} from '../verification-service';
import { AnalyticsService } from './analytics-service';
import { singularSDK } from './providers';
import { DeviceInfo, ScreenNameType } from './types';
import {
  getIdVerificationName,
  getScreenNameByPathname,
  mapSyncUserKeys,
} from './utils/analytics-service-helper';

export const AnalyticsContext = createContext<AnalyticsService | undefined>(
  undefined,
);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const pathname = usePathname();
  const hasMounted = useRef(false);

  const { deviceInfo } = useDeviceInfo();
  const isLoggedIn = checkUserLoggedIn();

  const { data } = useGetMyProfile({
    retry: false,
    enabled: isLoggedIn,
  });

  const analyticsService = useMemo(() => {
    return new AnalyticsService([singularSDK]);
  }, []);

  const handleScreenViewEvent = () => {
    analyticsService.track({
      name: 'screen_view',
      data: {
        screen_name: getScreenNameByPathname(pathname as ScreenNameType),
        url: pathname,
      },
    });
  };

  const debouncedScreenViewEvent = debounce(handleScreenViewEvent, 2000);

  const handleDocumentVerification = (
    status: string,
    idDocType: VerificationDocType | 'DRIVERS_LICENSE',
  ) => {
    switch (status) {
      case 'SUCCESS':
        return `${getIdVerificationName(idDocType)}_verification_success`;
      case 'FAILED':
        return `${getIdVerificationName(idDocType)}_verification_failed`;
      default:
        return '';
    }
  };

  useEffect(() => {
    function onDocumentVerification(config: Record<string, any>) {
      const {
        status,
        payload: { idDocSetType, idDocType },
      } = config;
      if (idDocSetType === 'IDENTITY') {
        const eventName = handleDocumentVerification(status, idDocType);
        analyticsService.track({
          name: eventName,
          data: {},
        });
      }
    }

    function onLivelinessPending(payload: Record<string, any>) {
      analyticsService.track({
        name: 'kyc2_pending_review',
        data: {},
      });
    }

    analyticsService.on('documentVerificationCallback', onDocumentVerification);

    verificationService.on('livelinessPendingCallback', onLivelinessPending);

    return () => {
      analyticsService.off(
        'documentVerificationCallback',
        onDocumentVerification,
      );

      verificationService.off('livelinessPendingCallback', onLivelinessPending);
    };
  }, []);

  useEffect(() => {
    if (deviceInfo) {
      const deviceInfoObject: DeviceInfo = {
        device_id: deviceInfo?.deviceId,
        device_type: deviceInfo?.type,
        platform: 'web',
      };
      analyticsService.syncDeviceInfo(deviceInfoObject);
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (!data) return;
    analyticsService.syncUser(mapSyncUserKeys(data));

    // delay execution of screen view event, and clean up the timer if the component unmounts in less than two seconds to avoid redundant calls as the onboarding controller recalculates the current step
    debouncedScreenViewEvent();
    return () => debouncedScreenViewEvent.cancel();
  }, [data, pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      handleScreenViewEvent();
    }
    if (!hasMounted.current) {
      hasMounted.current = true;
    } else {
      /**
       * This is very specific user case to Singular Provider, as it need pageVisit method to run only after second page visit, on mount it will run automatically
       */
      analyticsService.pageVisit();
    }
  }, [pathname]);

  return (
    <AnalyticsContext.Provider value={analyticsService}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Custom hook to use the AnalyticsService
export const useAnalytics = (): AnalyticsService => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
