import { isValid, parse } from 'date-fns';

export const getAgeFromDate = (date: string) => {
  const timeArray = [0, 0, 0, 0];
  const [year, month, day] = date.split('-');
  const todaysDate = new Date();
  const today = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    todaysDate.getDate(),
    ...timeArray,
  );
  const dateObject = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    ...timeArray,
  );
  const ageInMilliseconds = today.getTime() - dateObject.getTime();
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
  const ageInYears = ageInMilliseconds / millisecondsInYear;
  return ageInYears;
};

export const isValidDate = (dateString: string, format: string): boolean => {
  if (!dateString) return false;

  const parsedDate = parse(dateString, format, new Date());
  return isValid(parsedDate);
};

export const validateDateOfBirth = {
  invalidDate: (date: string) => {
    return (
      (date.length === 10 && isValidDate(date, 'yyyy-MM-dd')) ||
      'Date of birth is invalid'
    );
  },
  atMost110YearsOld: (date: string) => {
    return (
      (getAgeFromDate(date) <= 110 && getAgeFromDate(date) > 0) ||
      'Check if you entered your year of birth correctly'
    );
  },
  atleast18YearsOld: (date: string) => {
    return getAgeFromDate(date) >= 18 || 'You must be at least 18 years old';
  },
};
